import { Component, TemplateRef } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
// import icons from 'glyphicons'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router) {
  }
}
