 // App Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppBootstrapModule } from './app-bootstrap/app-bootstrap.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


import { BsModalRef } from 'ngx-bootstrap/modal';
import { appEqualValidator } from './equal-validator.directive';

 // Services
import { APIService } from './service/api.service';
import { Okta } from './shared/okta/okta.service';
import Configuration from './.environment.config';
// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RegisterComponent } from './register/register.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import {
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { ProfileComponent } from './profile/profile.component';
import { ReferralComponent } from './referral/referral.component';

const oktaConfig = Object.assign({
  onAuthRequired: ({oktaAuth, router}) => {
    // Redirect the user to your custom login page
    router.navigate(['/']);
  }
}, Configuration.oidc);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    RegisterComponent,
    appEqualValidator,
    UserHomeComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    ReferralComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    AppBootstrapModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    FormsModule,
    OktaAuthModule.initAuth(oktaConfig),
    AppRoutingModule
  ],
  providers: [Okta, APIService, BsModalRef ],
  bootstrap: [AppComponent]
})
export class AppModule { }
