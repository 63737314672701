import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '../service/api.service';
import { User } from '../user.interface';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  requestStatus;
  failedRequest;
  statusMessage;
  requestToken;
  userId;
  public user: User;
  userEmail: any;

  constructor(private router: Router,
              private apiService: APIService) { }

  ngOnInit() {
    this.user = {
      firstName: '',
      lastName: '',
      email: '',
      Confirmemail: '',
      password: '',
      confirmPassword: ''
    };
  }

  onSubmit(form: any, isValid: boolean) {
    this.failedRequest = false;
    this.requestStatus = false;
    this.apiService.getUserByUsername(form)
    .subscribe((res: any) => { // Success Subject
      this.userId = res.id;
      this.userEmail = form['username'];
      // Sending Email
      this.sendMail();
    },
    err => { // Failure Subject
      this.failedRequest = true;
      this.statusMessage = 'User Not Found. Please enter the correct email address.';
    });
  }

  // Unused method (Using Okta sent email)
  /*
  getForgotPasswordToken() {
    this.apiService.getForgotPasswordToken(this.userId)
    .subscribe(res => {
      let splitUrl = res['resetPasswordUrl'].split('/');
      let token = splitUrl[splitUrl.length - 1];
      let resetLink = Configuration.app.testURL + '/resetpassword?onetimetoken=' + token;
      this.sendMail(resetLink);
    });
  }*/

  sendMail() {
    this.apiService.sendMail(this.userEmail);
    this.apiService.successSubject.subscribe(response => {
      if (response) {
        this.requestStatus = true;
        this.failedRequest = false;
        this.statusMessage = 'Email has been sent successfully to your email with instructions!';
      }
    });
    this.apiService.failureSubject.subscribe(
      response => {
      if ( response.status === 404) {
        this.failedRequest = true;
        this.requestStatus = false;
        this.statusMessage = 'Email  does not exist. Please enter a valid  email address.';
      } else {
        this.failedRequest = true;
        this.requestStatus = false;
        this.statusMessage = 'Unknown error has occured';
      }
    });
  }

  resetfield(): void {
    this.user.email = null;
  }

  RedirectToLogin() {
    console.log('Redirecting to login');
    this.router.navigate(['']);
  }
}
