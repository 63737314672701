import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProfileComponent } from './profile/profile.component';
import { ReferralComponent } from './referral/referral.component';

const routes: Routes = [
  { path: '',
   component: LoginComponent,
  },
  { path: 'register', component: RegisterComponent },
  { path: 'UserHome', component: ReferralComponent },
  { path: 'NotFound', component: NotFoundComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'referral', component: ReferralComponent }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor () {
    console.log('AppRoutingModule Loaded');
  }
}
