import { Component, OnInit } from '@angular/core';
import { APIService } from '../service/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  isValidToken = false;
  token;
  stateToken;
  requestStatus;
  failedRequest;
  statusMessage;
  isOpen = true;
    newpassword = {
      password: '',
      confirmPassword: ''
    };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private apiService: APIService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['onetimetoken'];
      if (!this.token) {
        alert('Invalid link');
        this.router.navigate(['']);
      } else {
          this.apiService.validateToken(this.token)
          .subscribe(res => {
            this.stateToken = res['stateToken'];
            this.isValidToken = true;
          },
          err => {
            this.stateToken = null;
            this.isValidToken = false;
            alert('Invalid Link');
          });
        }
    });
  }

  onSubmit(form: any, isValid: boolean) {
    // currently no validation - need to implement that - can take from other form
    this.apiService.resetPassword(this.stateToken, form['password']);
    this.apiService.successSubject.subscribe(
      response => {
        if (response.status === 'SUCCESS') {
          this.requestStatus = true;
          this.failedRequest = false;
          this.statusMessage = 'Password changed successfully.';
          this.isOpen = false;
        }
    });

    this.apiService.failureSubject.subscribe(
    response => {
      if (response.errorCauses[0].errorSummary.startsWith('Password requirements were not met. Password requirements:')) {
        this.failedRequest = true;
        this.requestStatus = false;
        this.resetfield();
        this.statusMessage = 'Password requirements were not met. Please try again';
      } else {
        this.failedRequest = true;
        this.requestStatus = false;
        this.resetfield();
        this.statusMessage = response.errorCauses[0].errorSummary;
      }
    });
  }

  resetfield(): void {
    this.newpassword.password = this.newpassword.confirmPassword = null;
  }

  RedirectToLogin() {
    this.router.navigate(['']);
  }
}
