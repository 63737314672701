import { Component, OnInit } from '@angular/core';
import { User } from '../user.interface';
import { Router } from '@angular/router';
import { APIService } from '../service/api.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  requestStatus;
  failedRequest;
  statusMessage;
  isLoading;
  isSuccess;
  errors;
  code;
  actualCode;
  error;
  errorFlag = false;
  navigator;
  textinput: '';
  public user: User;

  constructor(private router: Router, private apiService: APIService) { }

  ngOnInit() {
    this.Captcha();
    this.user = {
      firstName: '',
      lastName: '',
      email: '',
      Confirmemail: '',
      password: '',
      confirmPassword: ''
    };
  }

  Captcha() {
    let alpha;
    if (window.navigator.language === 'zh-CN' ) { // Deleted because it errors: || navigator.userLanguage=="zh-CN"
      alpha = new Array( '诶', '比', '西', '迪', '伊', '艾弗', '吉', '艾尺', '艾', '杰', '开', '艾勒', '艾马', '艾娜', '哦', '屁',
       '吉吾', '艾儿', '艾丝', '提', '伊吾', '维', '豆贝尔维', '艾克斯', '吾艾', '贼德', '零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
    } else {
      alpha = new Array( 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
       'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',
        's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9');
    }

    let i;
    for (i = 0; i < 6; i++) {
       var a = alpha[Math.floor(Math.random() * alpha.length)];
       var b = alpha[Math.floor(Math.random() * alpha.length)];
       var c = alpha[Math.floor(Math.random() * alpha.length)];
       var d = alpha[Math.floor(Math.random() * alpha.length)];
       var e = alpha[Math.floor(Math.random() * alpha.length)];
       var f = alpha[Math.floor(Math.random() * alpha.length)];
       var g = alpha[Math.floor(Math.random() * alpha.length)];
    }
      this.code = a + ' ' + b + ' ' + c + ' ' + d + ' ' + e + ' ' + f + ' ' + g;
      this.actualCode = a + b + c + d + e + f + g;
  }

  validation(form: any, isValid: boolean) {
  }

  resetfield() {
    (<HTMLInputElement>document.getElementById('txtInput')).value = '';
  }

  resetfields() {
    (<HTMLInputElement>document.getElementById('txtInput')).value =
    (<HTMLInputElement>document.getElementById('firstName')).value =
    (<HTMLInputElement>document.getElementById('lastName')).value =
    (<HTMLInputElement>document.getElementById('email')).value =
    (<HTMLInputElement>document.getElementById('confirmEmail')).value =
    (<HTMLInputElement>document.getElementById('password')).value =
    (<HTMLInputElement>document.getElementById('confirmPassword')).value = '';
 }

 clearMessage() {
   if (this.requestStatus) {
        this.requestStatus = false;
   }
   if (this.failedRequest) {
       this.failedRequest = false;
   }
 }

 clearCaptcha() {
  (<HTMLInputElement>document.getElementById('txtInput')).value = '';
 }

  onSubmit(form: any, isValid: boolean) {
    this.clearMessage();
    if (form.email !== form.Confirmemail) {
      this.failedRequest = true;
      this.clearCaptcha();
      this.Captcha();
      this.statusMessage = 'Emails do not match';
      return;
    } else if (form.password !== form.confirmPassword) {
      this.failedRequest = true;
      this.clearCaptcha();
      this.Captcha();
      this.statusMessage = 'Passwords do not match';
      return;
    }

    if (form.captcha === this.actualCode) {
      this.errorFlag = false;
      this.isLoading = true;
      this.apiService.createUser(form);
      this.apiService.successSubject.subscribe(
    data => {
      if (data.status === 'ACTIVE') {
        this.requestStatus = true;
        this.failedRequest = false;
        this.isSuccess = true;
        this.isLoading = false;
        this.statusMessage = 'An account was successfully created.';
        this.resetfields();
        this.Captcha();
      }
    }, error => {
        this.errors = error;
        this.isLoading = false;
    });
    this.apiService.failureSubject.subscribe(
    data => {
      if ( data === 'login: An object with this field already exists in the current organization') {
        this.failedRequest = true;
        this.requestStatus = false;
        this.resetfield();
        this.Captcha();
        this.statusMessage = 'Email already exists. Please use another email.';
      } else if ( data.startsWith('password: Password requirements were not met.')) {
        this.failedRequest = true;
        this.requestStatus = false;
        this.resetfield();
        this.Captcha();
        this.statusMessage = 'Password requirements were not met.';
      } else {
        this.failedRequest = true;
        this.requestStatus = false;
        this.resetfield();
        this.Captcha();
        this.statusMessage = data;
      }
    });
   } else {
     this.Captcha();
     (<HTMLInputElement>document.getElementById('txtInput')).value = '';
     this.error = 'Invalid Captcha!';
     this.errorFlag = true;
   }
  }

  redirectTologin() {
    this.router.navigate(['']);
  }

  showPassword(input: any): any {
   input.type = input.type === 'password' ?  'text' : 'password';
  }
}
