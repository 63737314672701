import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Okta } from '../shared/okta/okta.service';
import { HttpClient } from '@angular/common/http';
import Configuration from '../.environment.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  oktaSignIn;
  returnURL: any;
  embedLink: any;
  token: any;
  service: string;

  constructor(private router: Router,
              private okta: Okta,
              private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private http: HttpClient) {
  }

  showLogin() {
    this.oktaSignIn.renderEl({el: '#okta-login-container'}, (response) => {
      if (response.status === 'SUCCESS') {
        sessionStorage.clear();
        if (this.embedLink === 'UserHome') {
          console.log('coming from the top');
          this.router.navigate([this.embedLink]);
        } else {
          console.log('logging into community');
          window.location.href = this.embedLink;
        }
        this.oktaSignIn.hide();
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnInit() {
    /*  UNNECESSARY UNLESS WE USE WIDGET FOR PASSWORD RESET
    this.route.queryParams.subscribe(params => {
      this.token = params['onetimetoken'];
      if (this.token) {
        this.okta.recoveryToken = this.token;
      }
    });*/

    //Handle Microsoft Browsers
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent); //Potentially use later
    this.route.queryParams.subscribe(params => {
      this.service = params['service'];
      console.log("In query param: " + this.service);
    });

    //Check Session Storage
    if(!sessionStorage.getItem('ServiceName')) {
      //No Session Storage
      if(this.service){
        this.setEmbedService(this.service);
      } else {
        //Modern Browser - No service
        this.returnURL = document.referrer;
        console.log('Referrer: ' + this.returnURL);
        if(this.returnURL.startsWith(Configuration.returnURLs.fohf.toString())) {
          this.setEmbedService('FOHF');
        } else if(this.returnURL.startsWith(Configuration.returnURLs.ims.toString())) {
          this.setEmbedService('GL');
        } else if(this.returnURL.startsWith(Configuration.returnURLs.evercare.toString())) {
          this.setEmbedService('EC');
        } else {
          this.setEmbedService('default');
        }
      }
      //Broser Independent work
      sessionStorage.setItem('ServiceName', this.okta.serviceName);
      sessionStorage.setItem('EmbedLink', this.embedLink);
      this.okta.createWidget();
      this.oktaSignIn = this.okta.getWidget();
      console.log(this.okta.serviceName);
      this.oktaSignIn.session.get((response) => {
        if (response.status !== 'INACTIVE') {
          this.router.navigate(['UserHome']);
          this.changeDetectorRef.detectChanges();
        } else {
          this.showLogin();
        }
      });
    } else {
      //Using session storage
      console.log('Session storage used');
      this.embedLink = sessionStorage.getItem('EmbedLink');
      this.okta.serviceName = sessionStorage.getItem('ServiceName');
      this.okta.createWidget();
      this.oktaSignIn = this.okta.getWidget();
      this.oktaSignIn.session.get((response) => {
        if (response.status !== 'INACTIVE') {
          this.router.navigate(['UserHome']);
          this.changeDetectorRef.detectChanges();
        } else {
          this.showLogin();
        }
      });
    }
  }
  
  setEmbedService(service) {
    switch (service) {
      case 'FOHF':
      console.log('Match FOHF');
      this.embedLink = Configuration.embedLinks.fohf;
      this.okta.serviceName = Configuration.communityNames.fohf;
      break;
      case 'GL':
      console.log('Match GreenLight');
      this.embedLink = Configuration.embedLinks.ims;
      this.okta.serviceName = Configuration.communityNames.ims;
      break;
      case 'EC':
      console.log('Match Evercare');
      this.embedLink = Configuration.embedLinks.evercare;
      this.okta.serviceName = Configuration.communityNames.evercare;
      break;
      case 'ECEnroll':
      console.log('Match Evercare Enroll');
      this.embedLink = Configuration.embedLinks.ecenroll;
      this.okta.serviceName = Configuration.communityNames.evercare + ' Enroll';
      break;
      default:
      console.log('Match Default');
      this.embedLink = Configuration.embedLinks.default;
      this.okta.serviceName = Configuration.communityNames.default;
      break;
    }
  }

  logout() {
    this.oktaSignIn.signOut(() => {
      this.router.navigate(['']);
      this.changeDetectorRef.detectChanges();
      this.showLogin();
    });
  }
}
