import { Injectable } from '@angular/core';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { HttpClient  } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable';
import Configuration from '../../.environment.config';

@Injectable()
export class Okta {
  widget;
  serviceName;
  recoveryToken: any;

  constructor(public http: HttpClient) {}

  createWidget() {
    this.widget = new OktaSignIn({
      baseUrl: Configuration.oidc.baseURL,
      clientId: Configuration.oidc.clientId,
      redirectUri: Configuration.app.SERVERURL,
      logo: '/assets/HF Logo.png',
      i18n: {
        'en': {
          'primaryauth.title': 'Sign in to ' + this.serviceName,
          'primaryauth.username.placeholder': 'User Name'
        }
      },
      authParams: {
        issuer: 'default'
      },
      helpLinks: {
        forgotPassword: '/forgotpassword',
        help: 'https://www.tfaforms.com/4741896'
      },
      recoveryToken: this.recoveryToken,
      customButtons: [{
        title: 'Sign Up',
        className: 'btn-customAuth',
        click: function() {
          window.location.href = '/register';
        }
      }, 
{
        title: 'Forgot Password',
        className: 'btn-customAuth',
        click: function() {
          window.location.href = '/forgotpassword';
        }
      }

]

    });
  }

  getWidget() {
    return this.widget;
  }
}
