import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '../service/api.service';
import Configuration from '../.environment.config';
import * as OktaAuth from '@okta/okta-auth-js';


@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {
  Applications;
  config = {
    url: Configuration.oidc.baseURL,
    clientId: Configuration.oidc.clientId
  };
  authClient = new OktaAuth(this.config);

  constructor(private router: Router,
              private apiService: APIService,
              private changeDetectorRef: ChangeDetectorRef) {
   }

  ngOnInit() {
    this.authClient.session.get()
    .then(session => {
      this.getApplications(session.userId);
      if (session.status !== 'INACTIVE') {
        this.changeDetectorRef.detectChanges();
      } else {
        this.router.navigate(['']);
      }
    });
  }

  getApplications(id) {
    this.apiService.getApplications(id)
    .subscribe(res  => {
      this.Applications = res;
    },
    err => {
      console.log('Error occured', err);
    });
  }

  logout() {
    this.authClient.signOut()
    .then(() => {
      console.log('signout success');
      this.router.navigate(['']);
      this.changeDetectorRef.detectChanges();
      location.reload();
    })
    .fail(err => {
      console.log('error: ', err);
    });
  }

}
