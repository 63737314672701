import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '../service/api.service';
import Configuration from '../.environment.config';
import * as OktaAuth from '@okta/okta-auth-js';
import { formArrayNameProvider } from '../../../node_modules/@angular/forms/src/directives/reactive_directives/form_group_name';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  requestStatus;
  failedRequest;
  statusMessage;
  resetfail = true;
  config = {
    url: Configuration.oidc.baseURL,
    clientId: Configuration.oidc.clientId
  };
  firstName;
  lastName;
  user = {
    currentPassword: '',
    password: '',
    confirmPassword: ''
  };

  constructor(private router: Router, private apiService: APIService) {  }

  ngOnInit() {
    const authClient = new OktaAuth(this.config);
    authClient.session.get()
    .then(function(session) {
      if (session.status === 'ACTIVE') {
        authClient.token.getWithoutPrompt({
          scopes: ['openid', 'profile']
        })
        .then(function(tokenOrTokens) {
          // Manage tokens
          (<HTMLInputElement>document.getElementById('username')).value = tokenOrTokens.claims.preferred_username;
          (<HTMLInputElement>document.getElementById('firstName')).value = tokenOrTokens.claims.given_name;
          (<HTMLInputElement>document.getElementById('lastName')).value = tokenOrTokens.claims.family_name;
          (<HTMLInputElement>document.getElementById('oktaId')).value = tokenOrTokens.claims.sub;
        })
        .catch((err) => {
          // Error reading profile - redirect to login
          console.log('Error reading profile');
          console.log(err);
          this.router.navigate(['']);
        });
      } else {
        // Session is invalid - redirect to login page
        console.log('Session invalid');
        this.router.navigate(['']);
      }
    })
    .catch(err => {
      // Not logged in - redirect to login
      console.log('Not logged in');
      console.log(err);
      this.router.navigate(['']);
    });
  }

  updateProfile() {
    const form = {
      oktaId: (<HTMLInputElement>document.getElementById('oktaId')).value,
      firstName: (<HTMLInputElement>document.getElementById('firstName')).value,
      lastName: (<HTMLInputElement>document.getElementById('lastName')).value
    };
    if (this.firstName === form.firstName && this.lastName === form.lastName) return;
    console.log('Form: ', form);
    this.clearMessage();
    this.apiService.updateProfile(form);
    this.apiService.successSubject.subscribe(
      response => {
        if (response.status === 'ACTIVE') {
          this.requestStatus = true;
          this.failedRequest = false;
          this.statusMessage = 'Profile updated successfully.';
        }
    });

    this.apiService.failureSubject.subscribe(
    response => {
      console.log('Failure: ', response);
      if (response.errorCauses[0].errorSummary.startsWith('firstName: The field cannot be left blank')) {
        this.failedRequest = true;
        this.requestStatus = false;
        this.statusMessage = 'First Name cannot be blank';
      } else if (response.errorCauses[0].errorSummary.startsWith('lastName: The field cannot be left blank')) {
        this.failedRequest = true;
        this.requestStatus = false;
        this.statusMessage = 'Last Name cannot be blank';
      } else {
        this.failedRequest = true;
        this.requestStatus = false;
        this.statusMessage = response.errorCauses[0].errorSummary;
      }
    });
  }

  changePassword(ngForm: any, isValid: boolean) {
   
    let confirmPassword = (<HTMLInputElement>document.getElementById('confirmPassword')).value;
    const form = {
      oktaId: (<HTMLInputElement>document.getElementById('oktaId')).value,
      oldPassword: (<HTMLInputElement>document.getElementById('currentPassword')).value,
      newPassword: (<HTMLInputElement>document.getElementById('newPassword')).value
    };

    this.clearMessage();
    if (form.oldPassword === form.newPassword) {
     this.failedRequest = true;
     this.requestStatus = false;
     this.statusMessage = 'Old password and new password cannot be the same';
     return;
   }

    console.log(' ngForm Form: ', ngForm);
    console.log('Form: ', form);
    this.apiService.changePassword(form);
    this.apiService.successSubject.subscribe(
      response => {
        console.log('password change: ', response);
        if (response.status === 'ACTIVE') {
          console.log('password change success');
          this.failedRequest = false;
          this.requestStatus = true;
          this.statusMessage = 'Password changed successfully.';
          this.resetfield();
        }
    });

    this.apiService.failureSubject.subscribe(
    response => {
      console.log('error: ', response.errorCauses[0].errorSummary);
      if (response.errorCauses[0].errorSummary.startsWith('password: Password requirements were not met. Password requirements:')) {
        this.failedRequest = true;
        this.requestStatus = false;
        this.statusMessage = 'Password requirements were not met. Please try again';
        this.resetfield();
      } else {
        this.failedRequest = true;
        this.requestStatus = false;
        this.statusMessage = response.errorCauses[0].errorSummary;
      }
    });
  }

  resetfield() {
    (<HTMLInputElement>document.getElementById('currentPassword')).value =
    (<HTMLInputElement>document.getElementById('newPassword')).value =
    (<HTMLInputElement>document.getElementById('confirmPassword')).value = '';
  }

  clearMessage() {
    if (this.requestStatus) {
      this.requestStatus = false;
    }
    if (this.failedRequest) {
      this.failedRequest = false;
    }
  }

  Accountshow() {
    (<HTMLInputElement>document.getElementById('div1')).style.display = 'block';
    (<HTMLInputElement>document.getElementById('div2')).style.display = 'none';
    this.clearMessage();
  }

  Passwordshow() {
     (<HTMLInputElement>document.getElementById('div1')).style.display = 'none';
     (<HTMLInputElement>document.getElementById('div2')).style.display = 'block';
     this.clearMessage();
  }
}
