export default {
  app: {
    testURL: 'http://localhost:4200',
    HTTPSURL: 'http://localhost:8443',
    SERVERURL: 'https://login-test.hu-friedy.com'
  },
  oidc: {
    baseURL: 'https://hu-friedy-portal.oktapreview.com',
    clientId: '0oafim6h0oWGCGBkJ0h7',
    issuer: 'https://hu-friedy-portal.oktapreview.com/oauth2/default',
    redirectUri: 'http://localhost:4200'
  },
  returnURLs: {
    fohf: 'https://pcspring18-friendsofhu-friedy.cs26.force.com/',
    ims: 'https://ims-friendsofhu-friedy.cs90.force.com/',
    evercare: 'https://evercare-friendsofhu-friedy.cs21.force.com/EverCare/s/'
  },
  communityNames: {
    fohf: 'Friends of Hu-Friedy',
    ims: 'GreenLight',
    evercare: 'Evercare',
    default: 'My Hu-Friedy'
  },
  embedLinks: {
    fohf: 'https://hu-friedy-portal.oktapreview.com/home/hufriedymanufacturingexternalpreview_salesforcecustomapp_1/0oac6k12ccRJrba6P0h7/alnc6kcwyhdiTO56M0h7',
    ims: 'https://hu-friedy-portal.oktapreview.com/home/salesforce/0oaf9i08jz8o21rFt0h7/24',
    evercare: 'https://hu-friedy-portal.oktapreview.com/home/salesforce-fedid/0oafqxd3y6egcm6Fe0h7/1277',
    ecenroll: 'https://hu-friedy-portal.oktapreview.com/app/salesforce/exkfzq7y17vjQxbKk0h7/sso/saml?RelayState=/EverCare/s/enroll',
    default: 'UserHome'
  },
  api: {
    baseEndpoint: 'https://hu-friedy-portal.oktapreview.com/api/v1/',
    baseUsersEndpoint: 'https://hu-friedy-portal.oktapreview.com/api/v1/users/'
  }
};
