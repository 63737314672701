import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
import Configuration from '../.environment.config';

@Injectable()
export class APIService {
  public successSubject = new Subject<any>();
  public failureSubject = new Subject<any>();
  public loginSuccess = new Subject<any>();

  constructor(private http: HttpClient,
              protected router: Router) { }

  requestStatus: string;
  user: string;

  getUserByUsername(form) {
    return this.http.post(Configuration.app.SERVERURL + '/api/GetUserByUsername', form);
  }

  getApplications(id) {
   const body = { 'id': id};
   return this.http.post(Configuration.app.SERVERURL + '/api/getApplications', body);
  }

  updateProfile(form) {
    this.http.post(Configuration.app.SERVERURL + '/api/updateProfile', form)
    .subscribe(res => {
      this.successSubject.next(res);
    },
    err => {
      this.failureSubject.next(err.error);
    });
  }

  changePassword(form) {
    return this.http.post(Configuration.app.SERVERURL + '/api/changePassword', form)
    .subscribe(res => {
      this.successSubject.next(res);
    },
    err => {
      this.failureSubject.next(err.error);
    });
  }

  createUser(form) {
    this.http.post(Configuration.app.SERVERURL + '/api/CreateUser', form) // Remove https://localhost:8443 for server
    .subscribe(res => {
      this.successSubject.next(res);
      },
      err => {
        this.failureSubject.next(err.error.errorCauses[0].errorSummary.toString());
      }
    );
  }

  // Unused (Using Okta to send email)
  getForgotPasswordToken(userId) {
    const id = { 'userId': userId };
    return this.http.post(Configuration.app.SERVERURL + '/api/GetForgotPasswordToken', id);
  }

  validateToken(token) {
    const recoveryToken = { 'recoveryToken': token };
    return this.http.post(Configuration.app.SERVERURL + '/api/ValidateToken', recoveryToken);
  }

  resetPassword(stateToken, newPassword) {
    const body = {
      'stateToken': stateToken,
      'newPassword': newPassword
    };
    this.http.post(Configuration.app.SERVERURL + '/api/ResetPassword', body) // Remove https://localhost:8443 for server
      .subscribe(res => {
         this.successSubject.next(res);
        },
        err => {
          this.failureSubject.next(err.error);
        });
  }

  sendMail(userEmail) {
    const val = {
      'email': userEmail
      };
      this.http.post(Configuration.app.SERVERURL + '/api/SendEmail', val) // move https://localhost:8443 for server
      .subscribe(response => {
        this.successSubject.next(response);
      },
      err => {
        this.failureSubject.next(err.error);
      });
  }
}
