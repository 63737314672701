import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '../service/api.service';
import Configuration from '../.environment.config';
import * as OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})

export class UserHomeComponent implements OnInit {
  user;
  Applications;
  config = {
    url: Configuration.oidc.baseURL,
    clientId: Configuration.oidc.clientId
  };
  authClient = new OktaAuth(this.config);

  constructor(private router: Router,
              private apiService: APIService,
              private changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.authClient.session.get()
    .then(session => {
      this.getApplications(session.userId);
      if (session.status !== 'INACTIVE') {
        this.changeDetectorRef.detectChanges();
      } else {
        this.router.navigate(['']);
      }
    });
  }

  getApplications(id) {
    this.apiService.getApplications(id)
    .subscribe(res  => {
      this.Applications = res;
    },
    err => {
      console.log('Error occured', err);
    });
  }

  logout() {
    this.authClient.signOut(() => {
      this.router.navigate(['']);
      this.user = undefined;
      this.changeDetectorRef.detectChanges();
    });
  }
}
